.call-to-action-app{
	text-align: center;
	h2, p, a{
		color: $light !important;
	}
	p{
		margin-bottom: 60px;
	}
	ul{
		li{
			margin-left: 15px;
			@include mobile {
				margin-left: 0;
				margin-bottom: 10px;
			}
			&:first-child{
				margin-left: 0;
			}
			a{
				i{
					font-size: 20px;
					margin-right: 5px;
				}
			}
		}
	}
}

.cta-hire{
	background: #FAFAFA;
	p{
		width: 65%;
		margin: 0 auto;
	}
	h2, p{
		margin-bottom: 20px;
	}
}

.cta-community{
	margin: 50px 0;
	padding: 40px 100px;
	display: flex;
	@include tablet {
        flex-wrap: wrap;
        text-align: center;
	}
	@include mobile-xs {
		padding: 20px;
	}
	.content, .action-button{
		-webkit-justify-content: center;
		        justify-content: center;
	}
	.action-button{
		-webkit-align-self: center;
		        align-self: center;
	    @include tablet {
	    	width: 100%;
	    	text-align: center;
	    	margin-top: 20px;
	    }
	}
}